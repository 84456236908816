import React from "react";
import Logo from "../header/Logo";
import styles from "./CopyRight.module.css";

function CopyRight() {
  return (
    <div className={styles["Maincontainer"]}>
      <div className={styles.Container}>
        <div className={styles.Logos}>
          <Logo />
        </div>
        <p>GIẤY PHÉP KINH DOANH: 0318490652 do Sở Kế Hoạch Đầu Tư TPHCM cấp.<br />Copy Right © 2024 LANABASSAC. All rights reserved.</p>
      </div>
    </div>
  );
}

export default CopyRight;
