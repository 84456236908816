// AuthSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Tạo slice cho quản lý trạng thái xác thực người dùng
const authSlice = createSlice({
    name: 'auth', // Tên của slice
    initialState: {
        isLoggedIn: false, // Trạng thái đăng nhập
        isAdmin: false, // Trạng thái quản trị viên
    },
    reducers: {
        // Hàm xử lý đăng nhập
        login(state, action) {
            state.isLoggedIn = true; // Đặt trạng thái đăng nhập là true
            state.isAdmin = action.payload.isAdmin; // Thêm thuộc tính admin từ payload
            sessionStorage.setItem('isLoggedIn', 'true'); // Lưu trạng thái đăng nhập vào sessionStorage
            sessionStorage.setItem('isAdmin', action.payload.isAdmin); // Lưu trạng thái admin vào sessionStorage
        },

        // Hàm xử lý đăng xuất
        logout(state) {
            state.isLoggedIn = false; // Đặt trạng thái đăng nhập là false
            state.isAdmin = false; // Đặt trạng thái admin là false
            sessionStorage.removeItem('isLoggedIn'); // Xóa trạng thái đăng nhập khỏi sessionStorage
            sessionStorage.removeItem('isAdmin'); // Xóa trạng thái admin khỏi sessionStorage
        },

        // Hàm kiểm tra trạng thái đăng nhập
        checkLoginState(state) {
            const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true'; // Lấy trạng thái đăng nhập từ sessionStorage
            const isAdmin = sessionStorage.getItem('isAdmin') === 'true'; // Lấy trạng thái admin từ sessionStorage
            state.isLoggedIn = isLoggedIn; // Cập nhật trạng thái đăng nhập
            state.isAdmin = isAdmin; // Cập nhật trạng thái admin
        },
    },
});

// Xuất các action và reducer
export const { login, logout, checkLoginState } = authSlice.actions;
export default authSlice.reducer;
