import React from "react";
import Tourlist from "./Tourlist";
import SlideShow from "./Slideshow";
import { motion } from "framer-motion";
import styles from "./Body.module.css";
import BlogPosts from "./BlogPosts";

function Body({ onShowBlogContent, db }) {
  // Thêm props onShowBlogContent
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div id="blog" className={styles["blog-container"]}>
        <div id="home">
          <SlideShow db={db} />
        </div >
        <Tourlist db={db} />
        <div id="body">
          <BlogPosts onShowBlogContent={onShowBlogContent} db={db} />
        </div >

      </div>
    </motion.div >
  );
}

export default Body;