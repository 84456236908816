import React from "react";
import styles from "./Info.module.css"; // Import CSS Modules
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap
import FacebookSVG from "../common/FacebookSVG";
import YoutubeSVG from "../common/YoutubeSVG";
import ZaloSVG from "../common/ZaloSVG";
const Info = () => {
  return (
    <div className={`container ${styles["main-container"]}`}>
      <div className="row">
        {/* Cột 1 */}
        <div className={"col-md-3"}>
          <div className={styles["info-container"]}>
            <div className={styles['section-1']}>
              <h1>LANA BASSAC TRAVEL</h1>
              <h5>CÔNG TY TNHH THƯƠNG MAI DỊCH VỤ DU LỊCH LANA BASSAC</h5>
              <h6>Thông Tin Chuyển Khoản</h6>
              <p>Chủ Tài Khoản: NGUYEN THI TUYET NHUONG
                <br />
                Số Tài Khoản: 1902 0547 1510 13
                <br />
                Ngân Hàng: TECHCOMBANK
                <br />
                Chi Nhánh: An Hòa</p>
            </div>
          </div>
        </div>
        {/* Cột 2 */}
        <div className="col-md-3">
          <div className={styles["info-container"]}>
            <div className={styles["section-2"]}>
              <h1>THÔNG TIN LIÊN HỆ</h1>
              <span className={styles["info-icon"]}>
                contact@lanabassactravel.com
              </span>
              <br />
              <span className={styles["info-icon"]}>
                <a href="tel:+84382903738">+84.38.290.37.38</a>
              </span>
              <br />
              <span className={styles["info-icon"]}>
                <a href="https://www.google.com/maps/search/?api=1&query=205/10+Phan+Van+Han+street,+Binh+Thanh+dist,+HCMC">
                  205/10 Phan Van Han street Binh Thanh dist Ho Chi Minh City
                </a>
              </span>
              <br />
              <div className={styles.svgbox}>
                <a href="/" target="_blank">
                  <YoutubeSVG />
                </a>
                <a href="/" target="_blank">
                  <ZaloSVG />
                </a>
                <a href="/" target="_blank" >
                  <FacebookSVG />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Cột 3 */}
        <div className="col-md-3">
          <div className={styles["info-container"]}>
            <div className={styles["section-3"]}>
              <h1>GIỚI THIỆU</h1>
              <ul>
                <li><a href="/aboutus">Về chúng tôi</a></li>
                <li><a href="/paymentguide">Hướng dẫn thanh toán</a></li>
                <li><a href="/bookingguide">Hướng dẫn đặt tour</a></li>
                <li><a href="/">Bảng Giá</a></li>
                <li><a href="/">Tour Khuyển Mãi</a></li>
              </ul>
            </div>
          </div>
        </div>
        {/* Cột 4 */}
        <div className="col-md-3">
          <div className={styles["info-container"]}>
            <div className={styles["section-4"]}>
              <h1>CHÍNH SÁCH</h1>
              <ul>
                <li><a href="/">Chính sách bảo mật</a></li>
                <li><a href="/">Điều khoản chung</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Info;
