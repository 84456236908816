import React from "react";
import styles from "./Logo.module.css"; // Import the CSS Module

function Logo() {
  return (
    <div className={styles["logo-container"]}>
      <img src="https://firebasestorage.googleapis.com/v0/b/lanabassactravel-b476d.appspot.com/o/logo.svg?alt=media&token=255a2b6a-6f53-4f43-b35d-18f4f6c76d5e" alt="Logo" className={styles["logo-image"]} />
    </div>
  );
}

export default Logo;
