import React from "react";
// import style from "./Footer.module.css";
import Info from "./Info";
import CopyRight from "./CopyRight";
function Footer() {
  return (
    <div>
      <Info />
      <CopyRight />
    </div>
  );
}

export default Footer;
