import React from "react";
const FacebookSVG = () => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="50"
        height="50"
        viewBox="0,0,256,256"
    >
        <defs>
            <linearGradient
                x1="6.228"
                y1="4.896"
                x2="42.077"
                y2="43.432"
                gradientUnits="userSpaceOnUse"
                id="color-1_yGcWL8copNNQ_gr1"
            >
                <stop offset="0" stopColor="#0d61a9"></stop>
                <stop offset="1" stopColor="#16528c"></stop>
            </linearGradient>
        </defs>
        <g transform="translate(-38.4,-38.4) scale(1.3,1.3)">
            <g
                fill="none"
                fillRule="nonzero" // Thay đổi từ fill-rule
                stroke="none"
                strokeWidth="1" // Thay đổi từ stroke-width
                strokeLinecap="butt" // Thay đổi từ stroke-linecap
                strokeLinejoin="miter" // Thay đổi từ stroke-linejoin
                strokeMiterlimit="10" // Thay đổi từ stroke-miterlimit
                strokeDasharray=""
                strokeDashoffset="0"
                fontFamily="none" // Thay đổi từ font-family
                fontWeight="none" // Thay đổi từ font-weight
                fontSize="none" // Thay đổi từ font-size
                textAnchor="none" // Thay đổi từ text-anchor
            // style="mix-blend-mode: normal"
            >
                <g transform="scale(5.33333,5.33333)">
                    <path
                        d="M42,40c0,1.105 -0.895,2 -2,2h-32c-1.105,0 -2,-0.895 -2,-2v-32c0,-1.105 0.895,-2 2,-2h32c1.105,0 2,0.895 2,2z"
                        fill="url(#color-1_yGcWL8copNNQ_gr1)"
                    ></path>
                    <path
                        d="M25,38v-11h-4v-6h4v-2.138c0,-5.042 2.666,-7.818 7.505,-7.818c1.995,0 3.077,0.14 3.598,0.208l0.858,0.111l0.039,0.861v4.776h-3.635c-1.128,0 -1.365,1.378 -1.365,2.535v1.465h4.723l-0.928,6h-3.795v11z"
                        fill="#000000"
                        opacity="0.05"
                    ></path>
                    <path
                        d="M25.5,37.5v-11h-4v-5h4v-2.638c0,-4.788 2.422,-7.318 7.005,-7.318c1.971,0 3.03,0.138 3.54,0.204l0.436,0.057l0.02,0.442v4.253h-3.135c-1.623,0 -1.865,1.901 -1.865,3.035v1.965h4.64l-0.773,5h-3.868v11z"
                        fill="#000000"
                        opacity="0.07"
                    ></path>
                    <path
                        d="M33.365,16h2.635v-3.754c-0.492,-0.064 -1.531,-0.203 -3.495,-0.203c-4.101,0 -6.505,2.08 -6.505,6.819v3.138h-4v4h4v11h5v-11h3.938l0.618,-4h-4.556v-2.465c0,-1.874 0.612,-3.535 2.365,-3.535z"
                        fill="#ffffff"
                    ></path>
                </g>
            </g>
        </g>
    </svg>)
}
export default FacebookSVG