// FirebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAaioKWxjqU1wJ_-Df_ye9228txGBiAJ44",
    authDomain: "lanabassactravel-b476d.firebaseapp.com",
    projectId: "lanabassactravel-b476d",
    storageBucket: "lanabassactravel-b476d.appspot.com",
    messagingSenderId: "153171301177",
    appId: "1:153171301177:web:824e499f3d1ec234210ee8",
    measurementId: "G-L9CB3ZR0SE"
};  

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app)
const storage = getStorage(app);
export { app, db, auth, storage };