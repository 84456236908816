import React from "react";
import styles from "./Hero.module.css";
import Button1 from "../common/button1";

const Hero = React.memo(({ onScrollToBody }) => {
  return (
    <div className={`${styles.hero} d-flex flex-column`}>
      <div className="container">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-12 col-md-8 text-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/lanabassactravel-b476d.appspot.com/o/logo.svg?alt=media&token=255a2b6a-6f53-4f43-b35d-18f4f6c76d5e"
              alt="Logo"
              loading="lazy"
              className={`${styles.logo} mb-4`}
            />
            <div className={`${styles.box} mx-auto`}>
              <div className={styles.title}>
                <h1>LANA BASSAC</h1>
              </div>
              <br />
              <div className={styles.role}>
                <span className={styles.block}></span>
                <p>Explore Vietnam, enjoy Vietnam!</p>
              </div>
            </div>

            <Button1 onClick={onScrollToBody} buttonText="EXPLORE" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Hero;
