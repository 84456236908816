import React from "react";

const YoutubeSVG = () => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="50"
        height="50"
        viewBox="0,0,256,256"
    >
        <g transform="translate(-1.28,-1.28) scale(1.01,1.01)">
            <g
                fill="none"
                fillRule="nonzero" // Thay đổi từ fill-rule
                stroke="none"
                strokeWidth="1" // Thay đổi từ stroke-width
                strokeLinecap="butt" // Thay đổi từ stroke-linecap
                strokeLinejoin="miter" // Thay đổi từ stroke-linejoin
                strokeMiterlimit="10" // Thay đổi từ stroke-miterlimit
                strokeDasharray=""
                strokeDashoffset="0"
                fontFamily="none" // Thay đổi từ font-family
                fontWeight="none" // Thay đổi từ font-weight
                fontSize="none" // Thay đổi từ font-size
                textAnchor="none" // Thay đổi từ text-anchor
            // style="mix-blend-mode: normal"
            >
                <g transform="scale(6.4,6.4)">
                    <path
                        d="M20,38.5c-4.395,-0.001 -10.279,-0.106 -12.53,-0.298c-3.679,-0.324 -3.795,-4.391 -3.917,-8.696c-0.016,-0.533 -0.03,-1.062 -0.053,-1.58c0,-2.354 0.233,-5.083 0.608,-7.1c0.715,-3.026 2.36,-3.078 5.348,-3.172c0.447,-0.014 0.916,-0.029 1.403,-0.056c0.004,0 0.273,-0.004 0.557,-0.01c1.171,-0.027 3.913,-0.089 8.482,-0.089c0,0 0.177,0.001 0.211,0.001c4.602,0 7.313,0.062 8.475,0.088c0.307,0.007 0.5,0.011 0.584,0.011c0.46,0.026 0.929,0.041 1.376,0.055c2.987,0.094 4.633,0.146 5.353,3.195c0.37,1.993 0.604,4.722 0.604,7.098c-0.022,0.496 -0.037,1.025 -0.053,1.558c-0.122,4.305 -0.238,8.372 -3.918,8.696c-2.251,0.193 -8.135,0.298 -12.53,0.299z"
                        fill="#225c95"
                    ></path>
                    <path
                        d="M19.886,18h0.05h0.001l0.076,0.001l0.05,-0.001h0.044c4.56,0 7.296,0.062 8.465,0.089c0.281,0.006 0.469,0.01 0.566,0.011c0.482,0.026 0.946,0.041 1.389,0.055c2.918,0.092 4.25,0.133 4.872,2.764c0.37,1.99 0.6,4.684 0.601,6.986c-0.023,0.521 -0.038,1.053 -0.053,1.589c-0.137,4.787 -0.452,7.945 -3.46,8.21c-2.237,0.191 -8.103,0.295 -12.487,0.296c-4.384,-0.001 -10.251,-0.105 -12.485,-0.296c-3.011,-0.266 -3.326,-3.423 -3.463,-8.21c-0.015,-0.536 -0.03,-1.069 -0.052,-1.546c0,-2.346 0.23,-5.039 0.591,-6.983c0.631,-2.677 1.963,-2.719 4.882,-2.81c0.443,-0.014 0.907,-0.029 1.389,-0.055c0.097,-0.001 0.285,-0.005 0.566,-0.011c1.169,-0.027 3.907,-0.089 8.458,-0.089M20.102,17c-0.036,0 -0.067,0.001 -0.102,0.001c-0.035,0 -0.067,-0.001 -0.102,-0.001c-5.772,0 -8.671,0.1 -9.066,0.1c-3.711,0.204 -6.286,-0.3 -7.214,3.635c-0.413,2.218 -0.618,5.045 -0.618,7.213c0.205,4.698 -0.204,10.343 4.427,10.752c2.364,0.202 8.499,0.299 12.573,0.3c4.074,-0.001 10.209,-0.098 12.573,-0.3c4.631,-0.408 4.222,-6.054 4.427,-10.752c0,-2.168 -0.205,-4.994 -0.617,-7.212c-0.928,-3.936 -3.503,-3.432 -7.214,-3.635c-0.396,-0.001 -3.295,-0.101 -9.067,-0.101z"
                        fill="#163e63"
                    ></path>
                    <path
                        d="M19,35h-2v-1.375c-0.25,0.375 -0.875,1.374 -2.209,1.375c-0.666,0 -1.791,-0.5 -1.791,-2v-9h2v8.625c0,0.23 0.018,0.632 0.75,0.625c0.813,-0.008 1.25,-1 1.25,-1v-8.25h2z"
                        fill="#ffffff"
                    ></path>
                    <path
                        d="M12,23h-2v12h-2v-12h-2v-2h6z"
                        fill="#ffffff"
                    ></path>
                    <path
                        d="M27,26.56c0,-0.863 -0.273,-1.448 -0.622,-1.889c-0.346,-0.441 -0.856,-0.671 -1.505,-0.671c-0.325,0 -0.649,0.09 -0.973,0.255c-0.325,0.162 -0.67,0.445 -0.9,0.805v-4.06h-2v14h2v-1.375c0.386,0.451 0.949,1.386 2,1.375c1.5,0 2,-1.375 2,-2.5zM25,32.023c0,0.552 -0.448,0.977 -1,0.977c-0.552,0 -0.991,-0.212 -1,-0.5v-5.94c0.009,-0.503 0.448,-1 1,-1c0.552,0 1,0.439 1,1.44z"
                        fill="#ffffff"
                    ></path>
                    <path
                        d="M34,30v-3.116c0,-0.998 -0.5,-2.884 -3,-2.884c-2.375,0 -2.997,1.98 -2.997,2.884v4.708c0,1.023 0.278,1.813 0.808,2.392c0.535,0.579 1.276,0.858 2.227,0.858c1.038,0 1.83,-0.258 2.338,-0.815c0.536,-0.533 0.624,-1.342 0.624,-2.435v-0.592h-2v0.5c0,0.603 -0.059,1.066 -0.199,1.231c-0.138,0.184 -0.395,0.265 -0.801,0.269c-0.367,0.004 -0.646,-0.098 -0.784,-0.328c-0.141,-0.209 -0.216,-0.569 -0.216,-1.126v-1.546zM30,27c0.009,-0.537 0.448,-1 1,-1c0.552,0 1,0.385 1,1v1h-2z"
                        fill="#ffffff"
                    ></path>
                    <path
                        d="M15,1l-2,5.6l-2,-5.6h-2l3,8.4v5.6h2v-5.6l3,-8.4z"
                        fill="#163e63"
                    ></path>
                    <path
                        d="M28,4.5v7.75c0,0 -0.437,0.992 -1.25,1c-0.732,0.007 -0.75,-0.395 -0.75,-0.625v-8.125h-2v8.5c0,1.5 1.125,2 1.791,2c1.334,0 1.959,-1 2.209,-1.375v1.25h2v-10.375z"
                        fill="#163e63"
                    ></path>
                    <path
                        d="M19.75,15c-1.654,0 -3,-1.374 -3,-3.063v-4.5c0,-1.688 1.346,-3.063 3,-3.063c1.654,0 3,1.374 3,3.063v4.5c0,1.689 -1.346,3.063 -3,3.063zM19.75,6.25c-0.552,0 -1,0.495 -1,1.104v4.667c0,0.609 0.448,1.104 1,1.104c0.552,0 1,-0.495 1,-1.104v-4.667c0,-0.609 -0.448,-1.104 -1,-1.104z"
                        fill="#163e63"
                    ></path>
                </g>
            </g>
        </g>
    </svg>)
}
export default YoutubeSVG