import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "./Navbar.module.css";
import Logo from "./Logo";
import Logout from "../funtion/LogoutFuntion";
// import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const prevScrollPos = useRef(0);
  const [isVisible, setIsVisible] = useState(true);
  const menuRef = useRef(null);
  const location = useLocation();

  // Lấy trạng thái từ Redux store
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state) => state.auth.isAdmin); // Kiểm tra vai trò từ Redux

  // Hàm toggle dropdown menu trên mobile
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };


  // useEffect để kiểm tra sự thay đổi vị trí cuộn và thay đổi trạng thái của navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = prevScrollPos.current < currentScrollPos;

      if (isScrollingDown && currentScrollPos > 100) {
        setIsVisible(false);
        if (isMobileMenuOpen) {
          setIsMobileMenuOpen(false);
        }
      } else {
        setIsVisible(true);
      }

      prevScrollPos.current = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobileMenuOpen]);

  // useEffect để đóng menu khi click ra ngoài khu vực menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navbarVariants = {
    hidden: { y: -110 },
    visible: { y: 0 },
  };

  const isBlogContentPage = location.pathname.includes("/blog/");

  return (
    <motion.nav
      className={`${styles.navbar} navbar navbar-expand-lg fixed-top`}
      variants={navbarVariants}
      initial="visible"
      animate={isVisible ? "visible" : "hidden"}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className={`container ${styles.container}`}>
        <a href="/"><Logo className={styles.logo} /></a>
        <button
          className={`navbar-toggler ${styles["menu-button"]}`}
          type="button"
          aria-controls="navbarNav"
          aria-expanded={isMobileMenuOpen ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={toggleMobileMenu}
        >
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
        </button>
        <div className={`navbar-collapse ${isMobileMenuOpen ? "show" : "collapse"}`} ref={menuRef}>
          <ul className={`navbar-nav ms-auto ${styles["nav-links"]}`}>
            <li className="nav-item" onClick={() => setIsMobileMenuOpen(false)}>
              <a className={`nav-link ${styles.link}`} href="/">HOME</a>
            </li>
            {!isBlogContentPage && (
              <li className="nav-item" onClick={() => {
                setIsMobileMenuOpen(false);
              }}>
                <a className={`nav-link ${styles.link}`} href="/blog">BLOG</a>
              </li>
            )}
            <li className="nav-item" onClick={() =>
              setIsMobileMenuOpen(false)}>
              <a
                className={`nav-link ${styles.link}`}
                href="/tourlist"
              >TOUR</a>
            </li>
            <li className="nav-item" onClick={() => {
              setIsMobileMenuOpen(false);
            }}>
              <a
                className={`nav-link ${styles.link}`}
                href="/gallery"
              > GALLERY</a>
            </li>
            <li className="nav-item" onClick={() => setIsMobileMenuOpen(false)}>
              <a
                className={`nav-link ${styles.link}`}
                href="/aboutus" // Use href to navigate directly
              >
                ABOUT US
              </a>
            </li>

            {/* Hiển thị menu "Admin Panel" nếu người dùng là admin và đã đăng nhập */}
            {isLoggedIn && isAdmin && (
              <>
                <li className="nav-item" onClick={() => setIsMobileMenuOpen(false)}>
                  <a className={`nav-link ${styles.link}`} href="/admin">DASHBOARD</a>
                </li>
                <li className="nav-item" onClick={() => setIsMobileMenuOpen(false)}>
                  <Logout className={`nav-link ${styles.link}`} />
                </li>
              </>
            )}
          </ul>
        </div>
      </div >
    </motion.nav >
  );
}

export default Navbar;
