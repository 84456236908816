import { motion, useReducedMotion } from "framer-motion";
import styles from "./Loadingpage.module.css";

const LoadingPage = () => {
  const shouldReduceMotion = useReducedMotion();

  // Nếu người dùng yêu cầu giảm thiểu chuyển động, không render component
  if (shouldReduceMotion) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 1, scale: 1 }}
      animate={{
        opacity: 0,
        scale: 0,
        transition: { duration: 1 },
      }}
      exit={{ opacity: 0, scale: 0 }}
      className={styles.loadingPage}
    >
      <motion.img
        src="img/logo2.png"
        alt="Logo"
        className={styles.logo}
        animate={{
          rotate: 360,
          transition: {
            duration: 3,
            repeat: Infinity,
            ease: "linear",
          },
        }}
      />
    </motion.div>
  );
};

export default LoadingPage;