
import React from 'react';
import styles from './button1.module.css'; // Import as a module

const Button1 = ({ onClick, buttonText }) => {
    return (
        <div>
            <button className={styles.button} onClick={onClick}>
                <span className={styles.circle} aria-hidden="true">
                    <span className={styles.icon + ' ' + styles.arrow}></span>
                </span>
                <span className={styles['button-text']}>{buttonText}</span>
            </button>
        </div>
    );
};

export default Button1;