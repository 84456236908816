import React from 'react'

const ZaloSVG = () => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="50"
        height="50"
        viewBox="0,0,256,256"
    >
        <g transform="translate(-62.72,-62.72) scale(1.49,1.49)">
            <g
                fill="none"
                fillRule="nonzero" // Thay đổi từ fill-rule
                stroke="none"
                strokeWidth="1" // Thay đổi từ stroke-width
                strokeLinecap="butt" // Thay đổi từ stroke-linecap
                strokeLinejoin="miter" // Thay đổi từ stroke-linejoin
                strokeMiterlimit="10" // Thay đổi từ stroke-miterlimit
                strokeDasharray=""
                strokeDashoffset="0"
                fontFamily="none" // Thay đổi từ font-family
                fontWeight="none" // Thay đổi từ font-weight
                fontSize="none" // Thay đổi từ font-size
                textAnchor="none" // Thay đổi từ text-anchor
            // style="mix-blend-mode: normal"
            >
                <g transform="scale(2.56,2.56)">
                    <path
                        d="M69,82h-38c-7.18,0 -13,-5.82 -13,-13v-38c0,-7.18 5.82,-13 13,-13h38c7.18,0 13,5.82 13,13v38c0,7.18 -5.82,13 -13,13z"
                        fill="#1d588c"
                    ></path>
                    <path
                        d="M82,63.61v5.39c0,7.18 -5.82,13 -13,13h-32.7l-8.15,-8.15c-0.09,-0.09 -0.15,-0.21 -0.15,-0.35l0.92,0.26c0.59,0.3 1.92,0.74 4.58,0.74c3.54,0 6.25,-1.24 9.2,-2.12c1.08,-0.33 2.25,-0.3 3.31,0.09c3.57,1.31 7.44,2.03 11.49,2.03c9.84,0 18.63,-4.24 24.5,-10.89z"
                        fill="#073d71"
                    ></path>
                    <path
                        d="M82,31v33.24c-6.19,6.76 -15.08,10.64 -24.5,10.64c-4.01,0 -7.94,-0.7 -11.66,-2.07c-0.95,-0.35 -2.02,-0.37 -2.99,-0.08c-0.67,0.2 -1.31,0.42 -1.96,0.63c-2.24,0.74 -4.57,1.52 -7.39,1.52c-4.09,0 -5.24,-1.03 -5.35,-1.15c-0.12,-0.12 -0.17,-0.29 -0.14,-0.45c0.03,-0.16 0.14,-0.3 0.29,-0.36c0.04,-0.02 4.34,-1.92 6.02,-5.55c0.33,-0.73 0.17,-1.6 -0.41,-2.18c-5.75,-5.79 -8.91,-13.36 -8.91,-21.31c0,-10.52 5.43,-20.11 14.59,-25.88h29.41c7.18,0 13,5.82 13,13z"
                        fill="#fefdef"
                    ></path>
                    <path
                        d="M71.5,40c-3.309,0 -6,2.916 -6,6.5c0,3.584 2.691,6.5 6,6.5c3.309,0 6,-2.916 6,-6.5c0,-3.584 -2.691,-6.5 -6,-6.5zM71.5,49.5c-1.309,0 -2.375,-1.346 -2.375,-3c0,-1.654 1.066,-3 2.375,-3c1.309,0 2.375,1.346 2.375,3c0,1.654 -1.066,3 -2.375,3z"
                        fill="#1d588c"
                    ></path>
                    <path
                        d="M61.75,35.5c-0.966,0 -1.75,0.783 -1.75,1.75v14c0,0.967 0.784,1.75 1.75,1.75c0.966,0 1.75,-0.783 1.75,-1.75v-14c0,-0.967 -0.784,-1.75 -1.75,-1.75z"
                        fill="#1d588c"
                    ></path>
                    <path
                        d="M54.5,46.5c0,1.657 -1.119,3 -2.5,3c-1.381,0 -2.5,-1.343 -2.5,-3c0,-1.657 1.119,-3 2.5,-3c1.381,0 2.5,1.343 2.5,3zM56.25,40.25c-0.732,0 -1.357,0.45 -1.618,1.088c-0.905,-0.836 -2.031,-1.338 -3.257,-1.338c-2.969,0 -5.375,2.91 -5.375,6.5c0,3.59 2.406,6.5 5.375,6.5c1.226,0 2.352,-0.502 3.257,-1.338c0.261,0.638 0.887,1.088 1.618,1.088c0.966,0 1.75,-0.783 1.75,-1.75v-9c0,-0.967 -0.784,-1.75 -1.75,-1.75z"
                        fill="#1d588c"
                    ></path>
                    <path
                        d="M43.25,49h-5.667l6.732,-9.756c0.549,-0.796 0.349,-1.886 -0.447,-2.435c-0.302,-0.208 -0.646,-0.308 -0.987,-0.309c-0.002,0 -0.005,-0.001 -0.007,-0.001h-8.374c-0.897,0 -1.625,0.728 -1.625,1.625c0,0.897 0.728,1.625 1.625,1.625h5.214l-6.904,10.007c-0.369,0.535 -0.411,1.231 -0.109,1.808c0.302,0.577 0.899,0.936 1.549,0.936h9c0.966,0 1.75,-0.783 1.75,-1.75c0,-0.967 -0.784,-1.75 -1.75,-1.75z"
                        fill="#1d588c"
                    ></path>
                    <g fill="#1d588c">
                        <path d="M71.5,53.5c-3.584,0 -6.5,-3.141 -6.5,-7c0,-3.859 2.916,-7 6.5,-7c3.584,0 6.5,3.141 6.5,7c0,3.859 -2.916,7 -6.5,7zM71.5,40.5c-3.033,0 -5.5,2.691 -5.5,6c0,3.309 2.467,6 5.5,6c3.033,0 5.5,-2.691 5.5,-6c0,-3.309 -2.467,-6 -5.5,-6zM71.5,50c-1.585,0 -2.875,-1.57 -2.875,-3.5c0,-1.93 1.29,-3.5 2.875,-3.5c1.585,0 2.875,1.57 2.875,3.5c0,1.93 -1.29,3.5 -2.875,3.5zM71.5,44c-1.034,0 -1.875,1.121 -1.875,2.5c0,1.379 0.841,2.5 1.875,2.5c1.034,0 1.875,-1.121 1.875,-2.5c0,-1.379 -0.841,-2.5 -1.875,-2.5z"></path>
                        <path d="M61.75,53.5c-1.241,0 -2.25,-1.01 -2.25,-2.25v-14c0,-1.24 1.009,-2.25 2.25,-2.25c1.241,0 2.25,1.01 2.25,2.25v14c0,1.24 -1.009,2.25 -2.25,2.25zM61.75,36c-0.689,0 -1.25,0.561 -1.25,1.25v14c0,0.689 0.561,1.25 1.25,1.25c0.689,0 1.25,-0.561 1.25,-1.25v-14c0,-0.689 -0.561,-1.25 -1.25,-1.25z"></path>
                        <path d="M51.375,53.5c-3.239,0 -5.875,-3.141 -5.875,-7c0,-3.859 2.636,-7 5.875,-7c1.121,0 2.192,0.371 3.13,1.08c0.419,-0.518 1.054,-0.83 1.745,-0.83c1.241,0 2.25,1.01 2.25,2.25v9c0,1.24 -1.009,2.25 -2.25,2.25c-0.691,0 -1.325,-0.312 -1.745,-0.83c-0.938,0.709 -2.009,1.08 -3.13,1.08zM51.375,40.5c-2.688,0 -4.875,2.691 -4.875,6c0,3.309 2.187,6 4.875,6c1.056,0 2.064,-0.417 2.917,-1.205c0.12,-0.11 0.289,-0.156 0.447,-0.121c0.16,0.035 0.292,0.147 0.355,0.299c0.193,0.473 0.647,0.777 1.155,0.777c0.689,0 1.25,-0.561 1.25,-1.25v-9c0,-0.689 -0.561,-1.25 -1.25,-1.25c-0.508,0 -0.962,0.305 -1.155,0.777c-0.062,0.151 -0.195,0.264 -0.355,0.299c-0.158,0.034 -0.327,-0.01 -0.447,-0.121c-0.853,-0.788 -1.861,-1.205 -2.917,-1.205zM52,50c-1.654,0 -3,-1.57 -3,-3.5c0,-1.93 1.346,-3.5 3,-3.5c1.654,0 3,1.57 3,3.5c0,1.93 -1.346,3.5 -3,3.5zM52,44c-1.103,0 -2,1.121 -2,2.5c0,1.379 0.897,2.5 2,2.5c1.103,0 2,-1.121 2,-2.5c0,-1.379 -0.897,-2.5 -2,-2.5z"></path>
                        <path d="M43.25,53h-9c-0.839,0 -1.603,-0.462 -1.992,-1.204c-0.39,-0.743 -0.336,-1.634 0.14,-2.324l6.363,-9.222h-4.261c-1.172,0 -2.125,-0.953 -2.125,-2.125c0,-1.172 0.953,-2.125 2.125,-2.125h8.375c0.011,0 0.022,0 0.034,0.001c0.447,0.007 0.876,0.144 1.244,0.396c0.495,0.342 0.827,0.855 0.936,1.446c0.108,0.592 -0.02,1.189 -0.361,1.685l-6.192,8.972h4.714c1.241,0 2.25,1.01 2.25,2.25c0,1.24 -1.009,2.25 -2.25,2.25zM34.5,37c-0.62,0 -1.125,0.505 -1.125,1.125c0,0.62 0.505,1.125 1.125,1.125h5.214c0.186,0 0.356,0.104 0.443,0.268c0.086,0.165 0.074,0.363 -0.031,0.517l-6.905,10.005c-0.265,0.384 -0.294,0.878 -0.078,1.291c0.217,0.413 0.641,0.669 1.107,0.669h9c0.689,0 1.25,-0.561 1.25,-1.25c0,-0.689 -0.561,-1.25 -1.25,-1.25h-5.667c-0.186,0 -0.356,-0.104 -0.443,-0.268c-0.086,-0.165 -0.074,-0.363 0.031,-0.517l6.732,-9.756c0.392,-0.567 0.249,-1.348 -0.319,-1.739c-0.216,-0.147 -0.449,-0.192 -0.733,-0.221h-8.351z"></path>
                        <path d="M57.5,74.875c-4.013,0 -7.937,-0.693 -11.663,-2.061c-0.952,-0.351 -2.015,-0.379 -2.991,-0.081c-0.662,0.197 -1.309,0.412 -1.956,0.627c-2.245,0.744 -4.566,1.515 -7.391,1.515c-4.089,0 -5.236,-1.029 -5.354,-1.146c-0.118,-0.117 -0.168,-0.284 -0.138,-0.447c0.031,-0.162 0.141,-0.299 0.292,-0.365c0.043,-0.019 4.339,-1.916 6.015,-5.55c0.337,-0.722 0.172,-1.596 -0.41,-2.178c-5.741,-5.787 -8.904,-13.355 -8.904,-21.314c0,-10.864 5.801,-20.745 15.518,-26.432c0.236,-0.139 0.544,-0.06 0.684,0.179c0.139,0.238 0.059,0.545 -0.179,0.685c-9.407,5.505 -15.023,15.062 -15.023,25.568c0,7.693 3.06,15.012 8.615,20.607c0.877,0.877 1.122,2.205 0.608,3.305c-1.359,2.945 -4.17,4.796 -5.621,5.594c0.677,0.233 1.885,0.494 3.898,0.494c2.663,0 4.806,-0.711 7.076,-1.464c0.656,-0.218 1.313,-0.436 1.981,-0.636c1.18,-0.359 2.469,-0.325 3.625,0.1c3.615,1.327 7.423,2 11.318,2c2.655,0 5.307,-0.321 7.88,-0.955c0.269,-0.064 0.539,0.098 0.605,0.366c0.066,0.268 -0.098,0.539 -0.366,0.604c-2.651,0.654 -5.383,0.985 -8.119,0.985z"></path>
                        <path d="M74.5,70.208c-0.17,0 -0.335,-0.087 -0.429,-0.243c-0.142,-0.236 -0.065,-0.544 0.172,-0.686c0.332,-0.199 0.661,-0.404 0.984,-0.614c0.233,-0.15 0.542,-0.085 0.692,0.146c0.151,0.231 0.085,0.541 -0.146,0.691c-0.334,0.218 -0.673,0.429 -1.016,0.634c-0.081,0.05 -0.169,0.072 -0.257,0.072z"></path>
                        <path d="M69.5,72.638c-0.201,0 -0.39,-0.121 -0.466,-0.319c-0.1,-0.258 0.028,-0.548 0.286,-0.647c1.083,-0.418 2.049,-0.846 2.954,-1.305c0.244,-0.123 0.547,-0.027 0.672,0.22c0.125,0.246 0.027,0.548 -0.22,0.673c-0.935,0.474 -1.931,0.914 -3.046,1.346c-0.059,0.021 -0.12,0.032 -0.18,0.032z"></path>
                        <path d="M69,83h-38c-7.72,0 -14,-6.28 -14,-14v-38c0,-7.72 6.28,-14 14,-14h38c7.72,0 14,6.28 14,14v38c0,7.72 -6.28,14 -14,14zM31,19c-6.617,0 -12,5.383 -12,12v38c0,6.617 5.383,12 12,12h38c6.617,0 12,-5.383 12,-12v-38c0,-6.617 -5.383,-12 -12,-12z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>)
}
export default ZaloSVG;