// LogoutFuntion.js
import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "./AuthSlice"; // Import action logout
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                dispatch(logout()); // Dispatch action logout
                navigate("/"); // Điều hướng về trang chủ
                console.log("Logout successful");
            })
            .catch((error) => {
                console.error("Error logging out: ", error);
            });
    };

    return (
        <a className="nav-link" href="/" onClick={handleLogout}>
            LOGOUT
        </a>
    );
};

export default Logout;
