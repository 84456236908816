// BlogPosts.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "./BlogPost.module.css";
import moment from "moment";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { collection, getDocs } from "firebase/firestore"; // Firestore SDK

const BlogPosts = ({ db }) => { // Nhận db từ props
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Để điều hướng

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch dữ liệu từ collection 'blogcontent' trên Firestore
        const querySnapshot = await getDocs(collection(db, "blogcontent"));
        const data = querySnapshot.docs.map(doc => doc.data());

        // console.log('Firestore data:', data);

        if (Array.isArray(data)) {
          const sortedPosts = data.sort((a, b) => {
            const dateA = moment(a.date, "DD-MM-YYYY");
            const dateB = moment(b.date, "DD-MM-YYYY");
            return dateB - dateA;
          });

          setPosts(sortedPosts);
          setIsLoading(false);
        } else {
          console.error("Error: Firestore did not return an array of data");
        }
      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };

    fetchData();
  }, [db]);

  return (
    <Container className={styles.container}>
      <h1 className={`text-center mb-5 ${styles.title}`}>BLOG</h1>

      {
        isLoading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <Row>
            {posts.map((post) => (
              <Col key={post.id} xs={12} sm={6} md={4} lg={4} className="mb-4">
                <Card className={styles["blog-post"]} onClick={() => navigate(`/blog/${post.id}`)}>
                  <Card.Img
                    variant="top"
                    src={post.img}
                    alt={post.title}
                    className={styles.image}
                    onError={(e) => { e.target.onerror = null; }}
                  />
                  <Card.Body>
                    <Card.Title>{post.title}</Card.Title>
                    <Card.Text>{post.summary}</Card.Text>
                    <Card.Text className="text-muted">{post.date}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )
      }
    </Container >
  );
};

export default BlogPosts;
